import React, { useEffect, useState } from "react";
import "./Table.css";

const ASC_ARROW = "↑";
const DESC_ARROW = "↓";

export function TablePresignedURL({ user }) {
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [presignedUrls, setPresignedUrls] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [sortOrder, setSortOrder] = useState({ column: null, ascending: true });

  const handleRenewClick = async (item, index) => {
    let parts = item.path.split("/");
    let fileName = parts[parts.length - 1];
    let directory = parts.slice(0, -1)[0];

    const bodyData = JSON.stringify({
      path: directory,
      fileName: fileName,
      presignedUrl: item.presignedUrl,
    });

    const options = {
      method: "POST",
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + user,
      }),
      body: bodyData,
    };

    await fetch(process.env.REACT_APP_API_ENDPOINT + "/renew", options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        item.presignedUrl = data.presignedUrl;
        item.path = "/" + data.path;
        item.createdAt = data.createdAt;
        item.expiresAt = data.expiresAt;
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleCopyClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedIndex(text);
      setTimeout(() => setCopiedIndex(null), 2000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  const fetchPresignedURLS = async () => {
    const options = {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + user,
      }),
    };

    await fetch(process.env.REACT_APP_API_ENDPOINT + "/urls", options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPresignedUrls(data);
        if (data.length > 0) {
          setShowTable(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPresignedURLS();
    document.getElementById("signInDiv").hidden = true;
  }, []);

  let sortedUrls = presignedUrls;

  if (showTable) {
    sortedUrls = presignedUrls.slice().sort((a, b) => {
    const column = sortOrder.column;
    const order = sortOrder.ascending ? 1 : -1;

    switch (column) {
      case "Shortened URL":
        return a.presignedUrl.localeCompare(b.presignedUrl) * order;
      case "Path":
        return a.path.localeCompare(b.path) * order;
      case "Created at":
        return (
          (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) *
          order
        );
      case "Expires at":
        return (
          (new Date(a.expiresAt).getTime() - new Date(b.expiresAt).getTime()) *
          order
        );
      default:
        return 0;
    }
  });
  }

  

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          margin: "auto",
        }}
      >
        <table id="urls">
          <thead>
            <tr>
              <th
                onClick={() =>
                  setSortOrder({
                    column: "Shortened URL",
                    ascending: !sortOrder.ascending,
                  })
                }
              >
                Shortened URL{" "}
                {sortOrder.column === "Shortened URL"
                  ? sortOrder.ascending
                    ? ASC_ARROW
                    : DESC_ARROW
                  : "-"}
              </th>
              <th
                onClick={() =>
                  setSortOrder({
                    column: "Path",
                    ascending: !sortOrder.ascending,
                  })
                }
              >
                Path{" "}
                {sortOrder.column === "Path"
                  ? sortOrder.ascending
                    ? ASC_ARROW
                    : DESC_ARROW
                  : "-"}
              </th>
              <th
                onClick={() =>
                  setSortOrder({
                    column: "Created at",
                    ascending: !sortOrder.ascending,
                  })
                }
              >
                Created at{" "}
                {sortOrder.column === "Created at"
                  ? sortOrder.ascending
                    ? ASC_ARROW
                    : DESC_ARROW
                  : "-"}
              </th>
              <th
                onClick={() =>
                  setSortOrder({
                    column: "Expires at",
                    ascending: !sortOrder.ascending,
                  })
                }
              >
                Expires at{" "}
                {sortOrder.column === "Expires at"
                  ? sortOrder.ascending
                    ? ASC_ARROW
                    : DESC_ARROW
                  : "-"}
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {showTable ? (
              sortedUrls.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      maxWidth: "350px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <span title={item.presignedUrl}>{item.presignedUrl}</span>
                    <br />
                    <button onClick={() => handleCopyClick(item.presignedUrl)}>
                      {copiedIndex === item.presignedUrl ? "Copied!" : "Copy"}
                    </button>
                  </td>
                  <td>/{item.path}</td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  {new Date() - new Date(item.expiresAt).toLocaleString() <=
                  7 * 24 * 60 * 60 * 1000 ? (
                    <td
                      style={{
                        backgroundColor: "red",
                      }}
                    >
                      {new Date(item.expiresAt).toLocaleString()}
                    </td>
                  ) : (
                    <td>{new Date(item.expiresAt).toLocaleString()}</td>
                  )}
                  <td>
                    <button
                      style={{
                        padding: "10px 10px",
                        background: "#8FBC8F",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "auto",
                        marginTop: "2%",
                        width: "100%",
                      }}
                      onClick={() => handleRenewClick(item, index)}
                    >
                      Renew
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No data available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
