import React, { useEffect, useState } from "react";
import { TablePresignedURL } from "./Table";

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [path, setPath] = useState("");
  // const [uploadResponseData, setUploadResponseData] = useState(null);
  const [user, setUser] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handlePathChange = (event) => {
    setPath(event.target.value);
  };

  const handleUpload = async() => {
    if (!selectedFile) return;

    const bodyData = JSON.stringify({
      path: path,
      fileName: selectedFile.name,
    });

    // let options = {
    //   method: "POST",
    //   mode: "cors",
    //   headers: new Headers({
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: "Bearer " + user,
    //   }),
    //   body: bodyData,
    // };

    await fetch(process.env.REACT_APP_API_ENDPOINT + "/upload", {
      method: "POST",
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + user,
      }),
      body: bodyData,
    })
      .then((response)=> {
        if (response.status === 401) {
          document.cookie =
            "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        return response.json();
      })
      .then(async (data) => {

        if (data) {
          // options = {
          //   method: "PUT",
          //   body: selectedFile,
          //   headers: {
          //     "Content-Type": selectedFile.type,
          //   },
          // };

          await fetch(data.presignedUrl, {
            method: "PUT",
            body: selectedFile,
            headers: {
              "Content-Type": selectedFile.type,
            },
          })
            .then((response) => {
              if (!response.ok) {
                console.error(
                  "Error uploading file:" + response.text()
                );
              }
              return response.json();
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
            });

          await fetch(process.env.REACT_APP_API_ENDPOINT + "/generate", {
            method: "POST",
            mode: "cors",
            headers: new Headers({
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user,
            }),
            body: bodyData,
          })
            .then((response)=> {
              if (response.status === 401) {
                document.cookie =
                  "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              }
              return response.json();
            })
            .catch((error) =>
              console.error("Error generating presigned url link:", error)
            );

        }

        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) =>
        console.error("Error generating upload presigned url link:", error)
      );







    // // Generates presigned url to upload
    // fetch(process.env.REACT_APP_API_ENDPOINT + "/upload", options)
    //   .then(async(response) => {
    //     if (response.status === 401) {
    //       document.cookie =
    //         "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //     }
    //     const data = await response.json();
    //     // setUploadResponseData(data);

    //     setShowAlert(true);
    //     setTimeout(() => {
    //       setShowAlert(false);
    //       window.location.reload();
    //     }, 2000);

    //     alert(data);

    //     if (data) {
    //       options = {
    //         method: "PUT",
    //         body: selectedFile,
    //         headers: {
    //           "Content-Type": selectedFile.type,
    //         },
    //       };

    //       fetch(data.presignedUrl, options)
    //         .then(async (response) => {
    //           if (!response.ok) {
    //             console.error(
    //               "Error uploading file:" + (await response.text())
    //             );
    //           }
    //         })
    //         .catch((error) => {
    //           console.error("Error uploading file:", error);
    //         });

    //       fetch(process.env.REACT_APP_API_ENDPOINT + "/generate", options)
    //         .then(async (response) => {
    //           if (response.status === 401) {
    //             document.cookie =
    //               "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    //           }
    //           const data = await response.json();
    //           // setUploadResponseData(data);
    //         })
    //         .catch((error) => {
    //           console.error("Error generating presigned url link:", error);
    //         });
    //       alert("Uploaded");
    //     } else {
    //       alert("Didn't upload, in else");
    //     }

    //     alert("End");
    //   })
    //   .catch((error) =>
    //     console.error("Error generating upload presigned url link:", error)
    //   );
  };

  const handleCallbackResponse = (response) => {
    setUser(response.credential);
    setCookie("jwt", response.credential, 1);
    document.getElementById("signInDiv").hidden = true;
  };

  function setCookie(name, value, hours) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000);

    const cookieValue = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieValue;
  }

  function getCookie(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    // google.accounts.id.prompt();
  }, []);

  return (
    <div className="App">
      <div id="signInDiv"></div>

      {getCookie("jwt") && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              margin: "auto",
            }}
          >
            <h3>Upload a file</h3>
            <input
              type="file"
              onChange={handleFileChange}
              style={{
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <h3>Enter the path</h3>
            <input
              type="text"
              value={path}
              onChange={handlePathChange}
              placeholder="Enter file path"
              style={{
                flex: 1,
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <button
              onClick={handleUpload}
              style={{
                padding: "10px 10px",
                background: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                margin: "auto",
                marginTop: "2%",
                width: "25%",
              }}
            >
              Upload
            </button>
          </div>

          <hr></hr>

          <div>
            <TablePresignedURL user={getCookie("jwt")} />
          </div>

          {showAlert && (
            <div
              style={{
                display: showAlert ? "block" : "none",
                padding: "20px",
                backgroundColor: "#4CAF50",
                color: "white",
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                zIndex: "999",
              }}
            >
              <span
                style={{
                  marginLeft: "15px",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "22px",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => setShowAlert(false)}
              >
                {/* &times; */}
              </span>
              <strong>Success!</strong> File uploaded!
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
